// import logo from './logo.svg';
import './App.css';
import React, { useEffect, useState } from 'react';

function App() {
  const [isTablet, setIsTablet] = useState(window.innerWidth < 1300);
  // const [isMobile, setIsMobile] = useState(window.innerWidth < 900);

  useEffect(() => {
    function base64Decode(data) {
      const b64 =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
      let o1,
        o2,
        o3,
        h1,
        h2,
        h3,
        h4,
        bits,
        i = 0,
        enc = '';

      do {
        h1 = b64.indexOf(data.charAt(i++));
        h2 = b64.indexOf(data.charAt(i++));
        h3 = b64.indexOf(data.charAt(i++));
        h4 = b64.indexOf(data.charAt(i++));
        bits = (h1 << 18) | (h2 << 12) | (h3 << 6) | h4;
        o1 = (bits >> 16) & 0xff;
        o2 = (bits >> 8) & 0xff;
        o3 = bits & 0xff;
        if (h3 === 64) enc += String.fromCharCode(o1);
        else if (h4 === 64) enc += String.fromCharCode(o1, o2);
        else enc += String.fromCharCode(o1, o2, o3);
      } while (i < data.length);

      return enc;
    }

    function getParameterByName(name) {
      name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
      const regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
      const results = regex.exec(window.location.search);
      return results === null
        ? ''
        : decodeURIComponent(results[1].replace(/\+/g, ' '));
    }

    const links = document.querySelectorAll('a');
    const to = getParameterByName('to');
    let afurl = '';

    if (to !== '') {
      afurl = base64Decode(to);
    } else if (getParameterByName('afclick')) {
      afurl += '?afclick=' + getParameterByName('afclick');
    }

    links.forEach((link) => {
      link.setAttribute('href', afurl);
      link.setAttribute('target', '_blank');
    });

    console.log('Links updated:', links);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsTablet(window.innerWidth < 1300);
      // setIsMobile(window.innerWidth < 900);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <div className="App">
        <img src="/img/zeus.gif" class="zeus" alt="Zeus" />
        <img src='/img/logo.svg' className="logo" alt="logo" />
        <img src='/img/olympus.png' className="olympus" alt="olympus" />
        {isTablet ?  
          <div className='center'>
            <img src='/img/mobile_bg.png' className="mobileDisplay" alt="mobileDisplay" />
            <img src='/img/1000x.gif' className="logo1000x" alt="1000x" />
          </div>: 
        <React.Fragment>
          <div className='left'>
            <img src='/img/mobile_bg.png' className="leftDisplay" alt="leftDisplay" />
            <img src='/img/1000x.gif' className="left1000x" alt="left1000x" />
          </div>
          <div className='right'>
            <img src='/img/mobile_bg.png' className="rightDisplay" alt="rightDisplay" />
            <img src='/img/1000x.gif' className="right1000x" alt="right1000x" />
          </div>
        </React.Fragment>}
      
        <div className='text_container'>
          <p>Ascenda ao Olimpo com um bônus de <span>+200%</span> nos seus primeiros depósitos!</p>
          <p>Use o código <span>"XXX"</span> ao se registrar e receba <span>30 FS!</span></p>
          <a className="btn" href='/'>
            <img src='/img/btn.svg' className="btnImg" alt="btn" />
          </a>
        </div>
        <div className='footer'>
          <img src='/img/mastercard.svg' className="mastercard" alt="mastercard" />
          <img src='/img/pix.svg' className="pix" alt="pix" />
          <img src='/img/visa.svg' className="visa" alt="visa" />
        </div>
    </div>
  );
}

export default App;
